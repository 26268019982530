import { createAction, props } from "@ngrx/store";
import { Pinsheet } from "../models/pinsheet.model";


export const pinsheetDetails = createAction(
    '[Pinsheet green details] retrieve green list', props<{ pinsheet: [] }>()
)
export const greensThumbnail = createAction(
    '[Greens Thumbnail] retrieve greens thumbnail', props<{ greenThumbnail: [] }>()
)
export const updatePinsheetAction = createAction(
    '[Is Pinsheet Updated]', props<{ pinsheetUpdate: boolean }>()
)
export const pinsheetDateAction = createAction(
    'Pinsheet Date', props<{ pinsheetDate: any }>()
)
export const updateGreenSpeed = createAction(
    'Update green speed', props<{ greenSpeed: any }>()
)
export const updateDaysOfHistory = createAction(
    'Update Days history speed', props<{ daysHistory: any }>()
)
export const thumbnailClickAction = createAction(
    'Thumbnail clicked', props<{ thumbnailClick: any }>()
)
export const ColorIndicationToggleAction = createAction(
    'Toggle Color Indication', props<{ colorIndication: boolean }>()
)
export const greensAngle = createAction(
    'Greens Angle', props<{ greenAngle: any }>()
)