import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { SideNavComponent } from './features/layout/side-nav/side-nav.component';
import { HeaderComponent } from './features/layout/header/header.component';
import { MaterialModule } from './features/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentComponent } from './features/layout/content/content.component';
import { ManageComponent } from './features/manage/manage.component';
import { PinsheetComponent } from './features/pinsheet/pinsheet.component';
import { EventsComponent } from './features/events/events.component';
import { HelpComponent } from './features/help/help.component';
import { LayoutRoutingModule } from './features/layout/layout-routing.module';
import { LayoutModule } from './features/layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CalendardropdownComponent } from './components/calendardropdown/calendardropdown.component';
import { DayStimpRangeComponent } from './components/day-stimp-range/day-stimp-range.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClientInfoComponent } from './features/client-info/client-info.component';
import { CookieService } from 'ngx-cookie-service';
import { FooterComponent } from './features/layout/footer/footer.component';
import { HttpService } from './core/services/http/http.service';
import { ErrorModule } from './core/errors/error.module';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './features/my-account/my-account.component';
import { ResourcesComponent } from './features/resources/resources.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToggleswitchComponent } from './components/toggleswitch/toggleswitch.component';
import { MenuiconmodalComponent } from './features/menuiconmodal/menuiconmodal.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment'; // Angular CLI environment
import { ConfigureComponent } from './features/pinsheet/pinsheetdetails/configure/configure.component';
import { ManagePinsheetComponent } from './features/pinsheet/pinsheetdetails/manage-pinsheet/manage-pinsheet.component';
import { ThumbnailListComponent } from './features/pinsheet/pinsheetdetails/thumbnail-list/thumbnail-list.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { EventsSettingComponent } from './features/events/events-setting/events-setting.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { QRCodeModule } from 'angularx-qrcode';
// Reducers
import { impersonateReducer, userReducer } from './store/reducers/user.reducer';
import { PinsheetdetailsComponent } from './features/pinsheet/pinsheetdetails/pinsheetdetails.component'
import { greensThumbnailReducer, pinsheetDateReducer, pinsheetReducer, greenSpeedReducer, daysHistoryReducer, updatePinsheetReducer, thumbnailClickReducer, colorIndicationToggleReducer } from './store/reducers/pinsheet.reducer';
import { managePinsheetReducer, manageRedirectReducer, pinsheetHistoryReducer, changedHistoryReducer } from './store/reducers/manage-pinsheet.reducer';
import { pinsheetDatesReducer } from './store/reducers/pinsheet-dates.reducer';
import { clientsProfileReducer, clientsReducer } from './store/reducers/clients.reducer';
import { FormatComponent } from './features/pinsheet/format/format.component'
import { courseReducer } from './store/reducers/course.reducer';
import { formatReducer,
         layoutSettingsReducer, displayLoaderReducer, displayThumbnailLoaderReducer, specialPinsheetFormatReducer, 
         courseListReducer, eventsFormatChangedReducer, formatChangedReducer, greenTextChangeReducer,
         formatHeaderTextReducer, formatSpecialHeaderTextReducer, formatHeaderImageReducer, formatSpecialHeaderImageReducer} from './store/reducers/format.reducer';
import { clearState } from './store/reducers/logout.reducer';
import { ColorPaletteComponent } from './features/pinsheet/settings/color-palette/color-palette.component'
import { BasicColorPaletteComponent } from './features/pinsheet/settings/basic-color-palette/basic-color-palette.component'
import { SettingsComponent } from './features/pinsheet/settings/settings.component';
import { ClientdropdownComponent } from './clientdropdown/clientdropdown.component';
import { FontDropdownComponent } from './font-dropdown/font-dropdown.component';
import { UserinfoComponent } from './features/userinfo/userinfo.component';
import { MultiselectdropdownComponent } from './components/multiselectdropdown/multiselectdropdown.component';
import { MatChipsModule } from '@angular/material/chips';
import { CreatetemplateComponent } from './features/createtemplate/createtemplate.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmmodalComponent } from './features/confirmmodal/confirmmodal.component';
import { AlertmessageComponent } from './components/alertmessage/alertmessage.component'
import { AlertsComponent } from './features/alerts/alerts.component'
import { FormatContentComponent } from './features/pinsheet/format/format-content/format-content.component';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { GreenComponent } from './features/manage/green/green.component';
import { GreensSettingComponent } from './features/manage/greens-setting/greens-setting.component';
import { StimpSectionComponent } from './features/manage/greens-setting/stimp-section/stimp-section.component';
import { HolesSectionComponent } from './features/manage/greens-setting/holes-section/holes-section.component';
import { GreensThumbnailComponent } from './features/manage/greens-thumbnail/greens-thumbnail.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { manageSelectedHolesReducer, selectManageGreenThumbNailReducer, manageCloseStimpTableReducer, manageActiveHolesReducer, manageDeleteReducer, manageHolesUpdatedReducer, selectAllManageGreenThumbnailReducer, clearSelectionReducer, holesTableReducer,enableDisableholeReducer } from './store/reducers/manage-green.reducer';
export const metaReducers: MetaReducer<any>[] = [clearState]
import { MatNativeDateModule } from '@angular/material/core';
import { manageHeaderTextUpdateReducer, manageToggleRestrictionReducer, manageUpdateDuplicateGreensReducer, templateIdReducer, eventIdReducer, greenIdReducer, manageToggleDetailsReducer, TemplateIDReducer, templateNameReducer, eventGreensThumbnailReducer, eventthumbnailClickReducer } from './store/reducers/events.reducer';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SortableDragdropComponent } from './components/sortable-dragdrop/sortable-dragdrop.component';
import { QuillModule } from 'ngx-quill'
import { QuilCustomToolbarComponent } from './components/quil-custom-toolbar/quil-custom-toolbar.component';
import { KonvaComponent } from './features/pinsheet/format/konva/konva.component';
import { KonvaPrintComponent } from './features/pinsheet/format/konva-print/konva-print.component';
import { ImpersonateDropdownComponent } from './components/impersonate-dropdown/impersonate-dropdown.component';
import { AuthorizeComponent } from './features/authorize/authorize.component';
import { LogoutComponent } from './features/logout/logout.component';
import { ExternalAuthenticationComponent } from './features/external-authentication/external-authentication.component';
import { ExternalPinsheetComponent } from './features/external-pinsheet/external-pinsheet.component';
import { PinsheetGridComponent } from './features/pinsheet/pinsheet-grid/pinsheet-grid.component';
import { HolePositionTablesComponent } from './features/pinsheet/hole-position-tables/hole-position-tables.component';
import { MemberPrintComponent } from './features/member-print/member-print.component';
import { MemberLoginComponent } from './features/member-login/member-login.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { TrainingLibraryComponent } from './features/training-library/training-library.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EpinsheetComponent } from './features/epinsheet/epinsheet.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SideNavComponent,
    HeaderComponent,
    ContentComponent,
    ManageComponent,
    PinsheetComponent,
    EventsComponent,
    HelpComponent,
    FooterComponent,
    DropdownComponent,
    CalendardropdownComponent,
    DayStimpRangeComponent,
    ClientInfoComponent,
    MyAccountComponent,
    SnackbarComponent,
    ToggleswitchComponent,
    ResourcesComponent,
    MenuiconmodalComponent,
    PinsheetdetailsComponent,
    ManagePinsheetComponent,
    ThumbnailListComponent,
    SpinnerComponent,
    MenuiconmodalComponent,
    ConfigureComponent,
    ClientdropdownComponent,
    FontDropdownComponent,
    MenuiconmodalComponent,
    FormatComponent,
    UserinfoComponent,
    MultiselectdropdownComponent,
    MenuiconmodalComponent,
    SettingsComponent,
    ColorPaletteComponent,
    BasicColorPaletteComponent,
    CreatetemplateComponent,
    ConfirmmodalComponent,
    AlertmessageComponent,
    MenuiconmodalComponent,
    FormatContentComponent,
    GreenComponent,
    GreensSettingComponent,
    StimpSectionComponent,
    HolesSectionComponent,
    GreensThumbnailComponent,
    EventsSettingComponent,
    AlertsComponent,
    SortableDragdropComponent,
    QuilCustomToolbarComponent,
    KonvaComponent,
    KonvaPrintComponent,
    ImpersonateDropdownComponent,
    AuthorizeComponent,
    LogoutComponent,
    ExternalAuthenticationComponent,
    ExternalPinsheetComponent,
    PinsheetGridComponent,
    MemberPrintComponent,
    HolePositionTablesComponent,
    MemberLoginComponent,
    TrainingLibraryComponent,
    EpinsheetComponent
  ],
  imports: [
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    NgbModule,
    ScrollingModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    ErrorModule,
    MatChipsModule,
    NgHttpLoaderModule.forRoot(),
    AccordionModule.forRoot(),
    CommonModule,
    RichTextEditorModule,
    NgxUiLoaderModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    TooltipModule.forRoot(),
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    QRCodeModule,
    QuillModule.forRoot(),
    StoreModule.forRoot(
      {
        user: userReducer,
        pinsheet: pinsheetReducer,
        managePinsheet: managePinsheetReducer,
        pinsheetDatesData: pinsheetDatesReducer,
        clientsData: clientsReducer,
        courseData: courseReducer,
        formatData: formatReducer,
        layoutSettings: layoutSettingsReducer,
        showLoader: displayLoaderReducer,
        clientProfile: clientsProfileReducer,
        greenThumbnail: greensThumbnailReducer,
        manageGreen: selectManageGreenThumbNailReducer,
        pinsheetDate: pinsheetDateReducer,
        redirectPinsheet: manageRedirectReducer,
        selectedHolesArr: manageSelectedHolesReducer,
        closeStimpTable: manageCloseStimpTableReducer,
        showThumbLoader: displayThumbnailLoaderReducer,
        activeHoles: manageActiveHolesReducer,
        holesApi: manageDeleteReducer,
        holesUpdated: manageHolesUpdatedReducer,
        toggleRestriction: manageToggleRestrictionReducer,
        headerTextContent: manageHeaderTextUpdateReducer,
        specialPinsheetFormatData: specialPinsheetFormatReducer,
        templateId: templateIdReducer,
        eventId: eventIdReducer,
        togglePinsheetHistory: pinsheetHistoryReducer,
        getPinsheetHistory: changedHistoryReducer,
        updateDuplicate: manageUpdateDuplicateGreensReducer,
        toggleDetailspage: manageToggleDetailsReducer,
        greenThumbnailData: selectAllManageGreenThumbnailReducer,
        templateID: TemplateIDReducer,
        templateName: templateNameReducer,
        greenId: greenIdReducer,
        greenSpeed: greenSpeedReducer,
        daysOfHistory: daysHistoryReducer,
        clearSelection: clearSelectionReducer,
        holesData: holesTableReducer,
        pinsheetUpdate: updatePinsheetReducer,
        courseList: courseListReducer,
        formatChanged: formatChangedReducer,
        eventsFormatChanged: eventsFormatChangedReducer,
        thumbnailClick:thumbnailClickReducer,
        eventGreenThumbnail:eventGreensThumbnailReducer,
        eventthumbnailClick:eventthumbnailClickReducer,
        enableDisablehole:enableDisableholeReducer,
        greenNameFontChanged:greenTextChangeReducer,
        impersonate: impersonateReducer,
        formatHeaderText: formatHeaderTextReducer,
        formatSpecialHeaderText: formatSpecialHeaderTextReducer,
        formatHeaderImage: formatHeaderImageReducer,
        formatSpecialHeaderImage: formatSpecialHeaderImageReducer,
        colorIndication: colorIndicationToggleReducer
      }, { metaReducers }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.PRODUCTION, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.PRODUCTION,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    CookieService, HttpService,
    Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
