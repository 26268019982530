import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginLayoutComponent } from "./login-layout/login-layout.component";
import { ContentComponent } from "./content/content.component";
import { AuthGuard } from "src/app/core/services/auth/auth.guard";
import { SecureInnerPagesGuard } from "src/app/core/services/auth/secure-inner-pages.guard";
import { MemberAuthGuard } from "src/app/core/services/member-auth/member-auth.guard";
import { SecureMemberPagesGuard } from "src/app/core/services/member-auth/secure-member-pages.guard";
const routes: Routes = [
    {
        path: 'epinsheet',
        loadChildren: () => import('../epinsheet/epinsheet.module').then(m=>m.EpinsheetModule)
    },
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                canActivate: [SecureInnerPagesGuard],
                loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'login',
                redirectTo: ''
            },
            {
                path: 'authorize',
                // canActivate: [SecureInnerPagesGuard],
                loadChildren: () => import('../authorize/authorize.module').then(m => m.AuthorizeModule)
            },
            {
                path: 'logout',
                // canActivate: [AuthGuard],
                loadChildren: () => import('../logout/logout.module').then(m => m.LogoutModule)
            },
            {
                path: 'externalauth',
                canActivate: [SecureInnerPagesGuard],
                loadChildren: () => import('../external-authentication/external-authentication.module').then(m => m.ExternalAuthenticationModule)
            },
            {
                path: 'external/pinsheet',
                loadChildren: () => import('../external-pinsheet/external-pinsheet.module').then(m => m.ExternalPinsheetModule)
            },
            {
                path: 'member',
                canActivate: [SecureMemberPagesGuard],
                loadChildren: () => import('../member-login/member-login.module').then(m => m.MemberLoginModule)
            },
            {
                path: 'member/pinsheet',
                canActivate: [MemberAuthGuard],
                loadChildren: () => import('../member-print/member-print.module').then(m => m.MemberPrintModule)
            },
        ]
    },
    {
        path: '',
        component: ContentComponent,
        children: [
            {
                path: 'pinsheet',
                canActivate: [AuthGuard],
                loadChildren: () => import('../pinsheet/pinsheet.module').then(m => m.PinsheetModule)
            },
            {
                path: 'manage',
                canActivate: [AuthGuard],
                loadChildren: () => import('../manage/manage.module').then(m => m.ManageModule)
            },
            {
                path: 'help',
                canActivate: [AuthGuard],
                loadChildren: () => import('../help/help.module').then(m => m.HelpModule)
            },
            {
                path: 'events',
                canActivate: [AuthGuard],
                loadChildren: () => import('../events/events.module').then(m => m.EventsModule)
            },
            {
                path: 'client-info',
                canActivate: [AuthGuard],
                loadChildren: () => import('../client-info/client-info.module').then(m => m.ClientInfoModule)
            },
            {
                path: 'myaccount',
                canActivate: [AuthGuard],
                loadChildren: () => import('../my-account/myaccount.module').then(m => m.MyaccountModule)
            },
            {
                path: 'alerts',
                canActivate: [AuthGuard],
                loadChildren: () => import('../alerts/alerts.module').then(m => m.AlertsModule)
            }
            ,
            {
                path: 'user-info',
                canActivate: [AuthGuard],
                loadChildren: () => import('../userinfo/userinfo.module').then(m => m.UserinfoModule)
            },
            {
                path: 'pinsheetdetails/:pinsheetId/:greenId',
                canActivate: [AuthGuard],
                loadChildren: () => import('../pinsheet/pinsheetdetails/pinsheetdetails.module').then(m => m.PinsheetdetailsModule)
            },
            {
                path: 'format',
                canActivate: [AuthGuard],
                loadChildren: () => import('../pinsheet/format/format.module').then(m => m.FormatModule)
            },
            {
                path: 'format',
                canActivate: [AuthGuard],
                loadChildren: () => import('../pinsheet/settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'konva',
                canActivate: [AuthGuard],
                loadChildren: () => import('../pinsheet/format/konva/konva.module').then(m => m.KonvaModule)
            },
            {
                path: 'training-library',
                canActivate: [AuthGuard],
                loadChildren: () => import('../training-library/training-library.module').then(m => m.TrainingLibraryModule)
            }
        ]
    }
    
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class LayoutRoutingModule { }